import React, { useState } from "react";
import { Row, Col, Card, Button, Form, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { login } from "../../services/authService";
import { toastBigError } from "components/utils/toasts";

const Login = () => {
  //   const history = useHistory();
  const [data, setData] = useState({
    username: "",
    password: "",
  });

  const forgotPassword = (event) => {
    event.preventDefault();
    window.location = "/recuperar";
    // history.push("/recuperar");
  };

  const doLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await login(data.username, data.password);
      if (response.data.success) {
        localStorage.setItem("token", response.data.token);
        window.location = "/";
        // history.push({ pathname: "/reservar" });
      } else {
        toastBigError("Acceso Denegado", "e-mail o contraseña incorrectos");
      }
    } catch {}
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      doLogin(event);
    }
  };

  const handleChange = ({ currentTarget: input }) => {
    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  return (
    <Row className="main-container align-items-center ">
      <Col className="card-col mx-auto" lg="4" md="6">
        <Card className="card-register blue">
          <h3 className="title login-title mx-auto ">
            <strong>Ingreso</strong>
          </h3>
          {/* <div className="social-line text-center">
            <Button className="btn-neutral btn-just-icon mt-0" color="facebook" href="#pablo" onClick={(e) => e.preventDefault()}>
              <i className="fa fa-facebook-square" />
            </Button>
            <Button className="btn-neutral btn-just-icon mt-0 ml-1" color="google" href="#pablo" onClick={(e) => e.preventDefault()}>
              <i className="fa fa-google-plus" />
            </Button>
            <Button className="btn-neutral btn-just-icon mt-0 ml-1" color="twitter" href="#pablo" onClick={(e) => e.preventDefault()}>
              <i className="fa fa-twitter" />
            </Button>
          </div> */}
          <Form
            className="register-form"
            onSubmit={(event) => {
              doLogin(event);
            }}
          >
            <label>e-mail</label>
            <InputGroup className="form-group-no-border">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-email-85" />
                </InputGroupText>
              </InputGroupAddon>
              {/* <Input autoFocus id="username" name="username" onChange={handleChange} value={data.username} error={errors["username"]} placeholder="e-mail" type="email" autoComplete="username" /> */}
              <Input autoFocus id="username" name="username" onChange={handleChange} value={data.username} placeholder="e-mail" type="email" autoComplete="username" />
            </InputGroup>
            <label>Contraseña</label>
            <InputGroup className="form-group-no-border">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-key-25" />
                </InputGroupText>
              </InputGroupAddon>
              <Input id="password" name="password" onChange={handleChange} onKeyPress={handleKeyPress} value={data.password} placeholder="contraseña" type="password" autoComplete="current-password" />
            </InputGroup>
            <Button
              block
              className="btn-round"
              color="danger"
              type="button"
              onClick={(event) => {
                doLogin(event);
              }}
            >
              Ingresar
            </Button>
          </Form>
          <div className="forgot">
            <Button
              className=" btn-forgot"
              onClick={(event) => {
                forgotPassword(event);
              }}
            >
              Olvidé mi contraseña
            </Button>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default Login;
