import http from "./httpService";
import { getConfig } from "../config/config.js";

const config = getConfig();
const serviceEndpoint = config.apiEndpoint + "/excepciones";

export async function getExcepciones(reserva, date) {
  try {
    const response = await http.get(serviceEndpoint, { params: { reserva: reserva, date: date } });
    return response.data.records;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

export async function saveExcepcion(data, reserva) {
  if (data._id) {
    return await http.put(serviceEndpoint + "/" + data._id, data);
  } else {
    return await http.post(serviceEndpoint, data);
  }
}
