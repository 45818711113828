import React, { useEffect, useState } from "react";
import loader from "../../assets/img/loader.gif";

export default function PreLoader({ onDoneLoading }) {
  const [timer, setTimer] = useState(null);

  const ready = () => {
    clearInterval(timer);
    setTimer(null);
    onDoneLoading();
  };

  useEffect(() => {
    const timer = setInterval(ready, 2000);
    setTimer(timer);
  }, []);

  return (
    <div>
      <div className="preloader flex-column justify-content-center align-items-center">
        <img src={loader} height="150px" alt="Cargando..." />
      </div>
    </div>
  );
}
