import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Row, Col, FormGroup, Input, Button, Form } from "reactstrap";
import Joi from "joi-browser";
import { toastBigError } from "components/utils/toasts";
import { getSala, saveSala } from "services/salaService";
import Switch from "react-bootstrap-switch";

const SalasForm = ({ props, onLoading, onDoneLoading }) => {
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState({ descripcion: "", requiereConfirmacion: false });
  const [errors, setErrors] = useState([]);
  const [action, setAction] = useState(location.state?.action ? location.state.action : "");
  const validateForm = true;

  const schema = {
    _id: Joi.string(),
    descripcion: Joi.string()
      .label("Descripción")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "any.empty":
              return { message: "Debe ingresar una descripción" };
            default:
              return { message: "Debe ingresar una descripción" };
          }
        });
      }),
    requiereConfirmacion: Joi.boolean(),
  };

  const fetchData = async (id) => {
    const record = await getSala(id);
    mapToViewModel(record);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const id = props.match.params.id;
    if (!id.includes("nuev")) {
      fetchData(id);
    } else {
      setAction("A");
    }
  }, []);

  const mapToViewModel = (sala) => {
    setData({
      _id: sala._id,
      descripcion: sala.descripcion,
      requiereConfirmacion: sala.requiereConfirmacion,
    });
  };

  const handleChange = (event) => {
    const input = event.currentTarget;
    const inputMode = event.target.inputMode;
    const cacheErrors = { ...errors };
    const cacheData = { ...data };
    if (validateForm) {
      const errorMessage = validateProperty(input, inputMode);
      if (errorMessage) {
        cacheErrors[input.name] = errorMessage;
      } else delete cacheErrors[input.name];
    } else delete cacheErrors[input.name];
    cacheData[input.name] = input.value;
    if (input.type === "checkbox") cacheData[input.name] = input.checked;
    setData(cacheData);
    setErrors(cacheErrors);
  };

  const handleChangeSwitch = (component, state) => {
    const name = component.props.name;
    const value = state;
    const cacheData = { ...data };
    cacheData[name] = value;
    setData(cacheData);
  };

  const validateRules = () => {
    return null;
  };

  const validate = () => {
    let errors = {};
    const options = { abortEarly: false };
    if (validateForm) {
      //   var { data } = this.state;
      Object.keys(data).map(function (key, index) {
        if ((key.toLowerCase().includes("dni") || key.toLowerCase().includes("documento")) && !key.toLowerCase().includes("tipo")) {
          const valor = data[key];
          const stripped = valor.replaceAll(".", "");
          if (stripped.length === 8) {
            const nuevoValor = stripped.substring(0, 2) + "." + stripped.substring(2, 5) + "." + stripped.substring(5, 8);
            data[key] = nuevoValor;
          }
          if (stripped.length === 7) {
            const nuevoValor = stripped.substring(0, 1) + "." + stripped.substring(1, 4) + "." + stripped.substring(4, 7);
            data[key] = nuevoValor;
          }
        }
        if (typeof data[key] === "object" && data[key] && data[key].value && typeof data[key].value === "string") {
          data[key] = data[key].value;
        }
      });
      let { error } = Joi.validate(data, schema, options);
      if (!error) {
        error = validateRules();
        if (!error) {
          Object.keys(data).map(function (key, index) {
            if (typeof data[key] === "object" && data[key] && data[key].value) data[key] = data[key].value;
          });
          return null;
        }
        error.map((error) => {
          toastBigError(error.message, 2500);
          return (errors[error.path] = error.message);
        });
        return errors;
      } else {
        error.details.map((validationError) => {
          return (errors[validationError.path[0]] = validationError.message);
        });
        return errors;
      }
    } else return null;
  };

  const validateProperty = (input, inputMode) => {
    let { name, value } = input;
    if (inputMode === "numeric") {
      const index = value.indexOf(" ");
      value = parseFloat(value.substr(value.indexOf(" ") + 1));
    }
    const object = { [name]: value };
    const checkSchema = { [name]: schema[name] };
    const { error } = Joi.validate(object, checkSchema);
    return error ? error.details[0].message : null;
  };

  const prepareData = () => {};

  const handleSubmit = (event) => {
    prepareData();
    if (event) event.preventDefault();
    const checkErrors = validate();
    if (checkErrors) {
      setErrors(checkErrors);
      return;
    }
    doSubmit();
  };

  const doSubmit = async () => {
    await saveSala(data);
    history.push({ pathname: "/salas" });
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <>
      <Container className="wrapper">
        <form onSubmit={handleSubmit}>
          <h3 className="mt-4 mb-2">Detalles de la Sala</h3>
          <Row>
            <Col sm="12">
              <FormGroup className={errors["descripcion"] ? "has-danger" : ""}>
                <Input
                  autoFocus
                  disabled={action === "C"}
                  className={errors["descripcion"] ? "text-control-danger" : ""}
                  placeholder="Nombre de la Sala"
                  type="text"
                  name="descripcion"
                  value={data.descripcion}
                  onChange={(event) => handleChange(event)}
                />
                {errors["descripcion"] ? <div className="form-control-feedback">{errors["descripcion"]}</div> : ""}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Row className="ml-0">
                <h5 className="mr-2">Requiere Confirmación</h5>
                <Switch onColor="success" onText="" offText="" disabled={action === "C"} offColor="default" name="requiereConfirmacion" value={data.requiereConfirmacion} onChange={(component, state) => handleChangeSwitch(component, state)} />
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-center mt-3">
            {action !== "C" ? (
              <Button outline className="btn-round mr-1" color="success" type="button" onClick={handleSubmit}>
                Guardar
              </Button>
            ) : (
              ""
            )}
            <Button outline className="btn-round mr-1" color="danger" type="button" onClick={goBack}>
              {action === "C" ? "Vovler" : "Cancelar"}
            </Button>
          </Row>
        </form>
      </Container>
    </>
  );
};

export default SalasForm;
