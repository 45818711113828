import http from "./httpService";
import { getConfig } from "../config/config.js";

const config = getConfig();
const serviceEndpoint = config.apiEndpoint + "/salas";

export async function getSala(recordId) {
  try {
    const response = await http.get(serviceEndpoint + "/" + recordId);
    return response.data.record;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

export async function getSalas() {
  try {
    const response = await http.get(serviceEndpoint);
    return response.data.records;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

export async function saveSala(data, parentId) {
  if (data._id) return await http.put(serviceEndpoint + "/" + data._id, data);
  else {
    data.parentId = parentId;
    return await http.post(serviceEndpoint, data);
  }
}

export async function deleteSala(recordId) {
  return await http.delete(serviceEndpoint + "/" + recordId);
}

export async function getSalasSelect() {
  let items = [];
  try {
    const response = await http.get(serviceEndpoint);
    response.data.records.map((item) => {
      items.push({ value: item._id, label: item.descripcion });
    });
    return items;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}
