import axios from "axios";
import logger from "./logService";
import { toastTimeout, toastError, toastBigError, toastInfo } from "../components/utils/toasts";

axios.defaults.withCredentials = true;

axios.defaults.headers.common["Authorization"] = localStorage.getItem("token");

axios.interceptors.response.use(null, (error) => {
  const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;
  if (!expectedError) {
    // toastBigError(error.message);
    logger.log(error);
  } else {
    switch (error.response.status) {
      case 400:
        toastInfo(error.response.data.message);
        break;
      case 401:
        localStorage.removeItem("token");
        toastTimeout("Sesión Inválida", "Su sesión expiró, o se abrió en otro dispositivo", 5000, true, "error", () => (window.location = "/login"));
        break;
      case 403:
        localStorage.removeItem("token");
        break;
      case 404:
        toastError("No se encontró ese registro en el servidor");
        break;
    }
  }
  return Promise.reject(error);
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
