import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Row, Col, FormGroup, Input, Button, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from "reactstrap";
import Joi from "joi-browser";
import Select from "react-select";
import { toastBigError } from "components/utils/toasts";
import { getUser, saveUser } from "services/userService";
import { getTeams } from "services/teamService";
import { getProfilesSelect } from "services/profileService";

const { TIPO_AGENTE } = require("../constants/constants");

const UsersForm = ({ props, onLoading, onDoneLoading }) => {
  const validateForm = true;
  const history = useHistory();
  const location = useLocation();
  const [profiles, setProfiles] = useState([]);
  const [teams, setTeams] = useState([]);
  const [errors, setErrors] = useState([]);
  const [action, setAction] = useState(location.state?.action ? location.state.action : "");
  const [data, setData] = useState({
    apellido: "",
    nombre: "",
    celular: "",
    email: "",
    team: "",
    profile: { value: "", label: "" },
    tipoAgente: { value: "", label: "" },
  });

  const [schema, setSchema] = useState({
    _id: Joi.string(),
    password: Joi.string(),
    apellido: Joi.string()
      .label("Apellido")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "any.empty":
              return { message: "Debe ingresar un apellido" };
            default:
              return { message: "Debe ingresar un apellido" };
          }
        });
      }),
    nombre: Joi.string()
      .label("Nombre")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "any.empty":
              return { message: "Debe ingresar un nombre" };
            default:
              return { message: "Debe ingresar un nombre" };
          }
        });
      }),
    celular: Joi.string()
      .label("Nº Celular")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "any.empty":
              return { message: "Debe ingresar un Nº de celular" };
            default:
              return { message: "Debe ingresar un Nº de celular" };
          }
        });
      }),
    email: Joi.string()
      .email()
      .label("e-mail")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "any.empty":
              return { message: "Debe ingresar un email" };
            default:
              return { message: "Debe ingresar un email" };
          }
        });
      }),
    profile: Joi.string()
      .label("Perfil")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "any.empty":
              return { message: "Debe ingresar Perfil de Usuario" };
          }
        });
      }),
    tipoAgente: Joi.string()
      .label("Perfil")
      .allow("")
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "any.empty":
              return { message: "Debe ingresar Perfil de Usuario" };
          }
        });
      }),
    team: Joi.string()
      .label("Perfil")
      .allow("")
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "any.empty":
              return { message: "Debe ingresar Perfil de Usuario" };
          }
        });
      }),
  });

  const fetchData = async (id) => {
    const record = await getUser(id);
    mapToViewModel(record);
  };

  const fetchDataExtra = async () => {
    const profiles = await getProfilesSelect();
    const teams = await getTeams();
    setTeams(teams);
    setProfiles(profiles);
  };

  useEffect(() => {
    // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const id = props.match.params.id;
    fetchDataExtra();
    if (!id.includes("nuev")) {
      fetchData(id);
    } else {
      setAction("A");

      // defaultValues();
    }
  }, []);

  const mapToViewModel = (user) => {
    setData({
      _id: user._id,
      nombre: user.nombre,
      apellido: user.apellido,
      email: user.email,
      celular: user.celular,
      team: { value: user.team, label: user.team },
      profile: { value: user.profile._id, label: user.profile.descripcion },
      tipoAgente: { value: user.tipoAgente, label: user.tipoAgente },
    });
  };

  const handleChange = (event) => {
    const input = event.currentTarget;
    const inputMode = event.target.inputMode;
    const cacheErrors = { ...errors };
    const cacheData = { ...data };
    if (validateForm) {
      const errorMessage = validateProperty(input, inputMode);
      if (errorMessage) {
        cacheErrors[input.name] = errorMessage;
      } else delete cacheErrors[input.name];
    } else delete cacheErrors[input.name];
    cacheData[input.name] = input.value;
    if (input.type === "checkbox") cacheData[input.name] = input.checked;
    setData(cacheData);
    setErrors(cacheErrors);
  };

  const handleSelectChange = (selectedOption, input) => {
    const value = selectedOption.value;
    const name = input.name;
    const cacheData = { ...data };
    const cacheErrors = { ...errors };
    const cacheSchema = { ...schema };
    if (name === "tipoAgente") {
      if (selectedOption.label !== "Junior") {
        cacheData["team"] = "";
        delete errors.team;
        cacheSchema.team = Joi.string()
          .label("Team")
          .allow("")
          .error((errors) => {
            return errors.map((error) => {
              switch (error.type) {
                default:
                  return { message: "Debe ingresar el team al que pertenece" };
              }
            });
          });
      } else {
        cacheSchema.team = Joi.string()
          .label("Team")
          .required()
          .error((errors) => {
            return errors.map((error) => {
              switch (error.type) {
                default:
                  return { message: "Debe ingresar el team al que pertenece" };
              }
            });
          });
      }
    }

    if (name === "profile") {
      if (selectedOption.label === "Administrador") {
        cacheData["tipoAgente"] = "";
        delete errors.tipoAgente;
        cacheSchema.tipoAgente = Joi.string()
          .label("tipo de Agente")
          .allow("")
          .error((errors) => {
            return errors.map((error) => {
              switch (error.type) {
                default:
                  return { message: "Debe ingresar un tipo de agente" };
              }
            });
          });
      } else {
        cacheSchema.tipoAgente = Joi.string()
          .label("Tipo de Agente")
          .required()
          .error((errors) => {
            return errors.map((error) => {
              switch (error.type) {
                default:
                  return { message: "Debe ingresar un tipo de agente" };
              }
            });
          });
      }
    }
    if (validateForm) {
      const errorMessage = validateSelect(name, value);
      if (errorMessage) cacheErrors[name] = errorMessage;
      else delete cacheErrors[name];
    } else delete cacheErrors[name];
    cacheData[name] = selectedOption;
    setSchema(cacheSchema);
    setData(cacheData);
    setErrors(cacheErrors);
  };

  const validateRules = () => {
    return null;
  };

  const validate = () => {
    let errors = {};
    const options = { abortEarly: false };
    if (validateForm) {
      //   var { data } = this.state;
      var cacheData = { ...data };
      Object.keys(cacheData).map(function (key, index) {
        if ((key.toLowerCase().includes("dni") || key.toLowerCase().includes("documento")) && !key.toLowerCase().includes("tipo")) {
          const valor = cacheData[key];
          const stripped = valor.replaceAll(".", "");
          if (stripped.length === 8) {
            const nuevoValor = stripped.substring(0, 2) + "." + stripped.substring(2, 5) + "." + stripped.substring(5, 8);
            cacheData[key] = nuevoValor;
          }
          if (stripped.length === 7) {
            const nuevoValor = stripped.substring(0, 1) + "." + stripped.substring(1, 4) + "." + stripped.substring(4, 7);
            cacheData[key] = nuevoValor;
          }
        }
        if (typeof cacheData[key] === "object" && cacheData[key] && cacheData[key].hasOwnProperty("value") && typeof cacheData[key].value === "string") {
          cacheData[key] = cacheData[key].value;
        }
      });
      let { error } = Joi.validate(cacheData, schema, options);
      if (!error) {
        error = validateRules();
        if (!error) {
          Object.keys(data).map(function (key, index) {
            if (typeof data[key] === "object" && data[key] && data[key].value) data[key] = data[key].value;
          });
          return null;
        }
        error.map((error) => {
          toastBigError(error.message, 2500);
          return (errors[error.path] = error.message);
        });
        return errors;
      } else {
        error.details.map((validationError) => {
          return (errors[validationError.path[0]] = validationError.message);
        });
        return errors;
      }
    } else return null;
  };

  const validateSelect = (name, value) => {
    const object = { [name]: value };
    const cacheSchema = { [name]: schema[name] };
    const { error } = Joi.validate(object, cacheSchema);
    return error ? error.details[0].message : null;
  };

  const validateProperty = (input, inputMode) => {
    let { name, value } = input;
    if (inputMode === "numeric") {
      const index = value.indexOf(" ");
      value = parseFloat(value.substr(value.indexOf(" ") + 1));
    }
    const object = { [name]: value };
    const checkSchema = { [name]: schema[name] };
    const { error } = Joi.validate(object, checkSchema);
    return error ? error.details[0].message : null;
  };

  const prepareData = () => {};

  const handleSubmit = (event) => {
    prepareData();
    if (event) event.preventDefault();

    const checkErrors = validate();
    if (checkErrors) {
      setErrors(checkErrors);
      return;
    }
    doSubmit();
  };

  const doSubmit = async () => {
    await saveUser(data);
    history.push({ pathname: "/usuarios" });
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <>
      <Container className="wrapper">
        <form onSubmit={handleSubmit}>
          <h3 className="mt-4 mb-2">Datos del Usuario</h3>
          <Row className="mb-2">
            <Col sm="6">
              <FormGroup className={errors["nombre"] ? "has-danger" : ""}>
                <Input autoFocus disabled={action === "C"} className={errors["nombre"] ? "text-control-danger" : ""} placeholder="Nombre" type="text" name="nombre" value={data.nombre} onChange={(event) => handleChange(event)} />
                {errors["nombre"] ? <div className="form-control-feedback">{errors["nombre"]}</div> : ""}
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup className={errors["apellido"] ? "has-danger" : ""}>
                <Input disabled={action === "C"} className={errors["apellido"] ? "text-control-danger" : ""} placeholder="Apellido" type="text" name="apellido" value={data.apellido} onChange={(event) => handleChange(event)} />
                {errors["apellido"] ? <div className="form-control-feedback">{errors["apellido"]}</div> : ""}
              </FormGroup>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col sm="6">
              <FormGroup className={errors["email"] ? "has-danger" : ""}>
                <Input disabled={action === "C"} className={errors["email"] ? "text-control-danger" : ""} placeholder="e-mail" type="email" name="email" value={data.email} onChange={(event) => handleChange(event)} />
                {errors["email"] ? <div className="form-control-feedback">{errors["email"]}</div> : ""}
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup className={errors["celular"] ? "has-danger" : ""}>
                <Input disabled={action === "C"} className={errors["celular"] ? "text-control-danger" : ""} placeholder="Nº Celular" type="celular" name="celular" value={data.celular} onChange={(event) => handleChange(event)} />
                {errors["celular"] ? <div className="form-control-feedback">{errors["celular"]}</div> : ""}
              </FormGroup>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md="6" sm="6">
              <FormGroup className={errors["profile"] ? "has-danger" : ""}>
                <Select
                  isSearchable={false}
                  isDisabled={action === "C"}
                  className={errors["profile"] ? "react-select react-select-danger" : "react-select react-select-default"}
                  placeholder="Perfil"
                  classNamePrefix="react-select"
                  name="profile"
                  value={data.profile}
                  onChange={(selectedOption, input) => handleSelectChange(selectedOption, input)}
                  options={profiles}
                />
                {errors["profile"] ? <div className="form-control-feedback text-danger ml-3">{errors["profile"]}</div> : ""}
              </FormGroup>
            </Col>
            <Col md="6" sm="6">
              {data.profile.label === "Agente" && (
                <FormGroup>
                  <Select
                    isSearchable={false}
                    isDisabled={action === "C"}
                    className={errors["tipoAgente"] ? "has-danger react-select react-select-default" : "react-select react-select-default"}
                    placeholder="Tipo de Agente"
                    classNamePrefix="react-select"
                    name="tipoAgente"
                    value={data.tipoAgente}
                    onChange={(selectedOption, input) => handleSelectChange(selectedOption, input)}
                    options={TIPO_AGENTE}
                  />
                  {errors["tipoAgente"] ? <div className="form-control-feedback text-danger">{errors["tipoAgente"]}</div> : ""}
                </FormGroup>
              )}
            </Col>
          </Row>
          {data.tipoAgente.label === "Junior" && (
            <Row className="mb-2">
              <Col md="6" sm="6">
                <FormGroup className={errors["team"] ? "has-danger" : ""}>
                  <Select
                    isSearchable={false}
                    isDisabled={action === "C"}
                    className={errors["team"] ? "react-select react-select-danger" : "react-select react-select-default"}
                    placeholder="Team al que pertenece"
                    classNamePrefix="react-select"
                    name="team"
                    value={data.team}
                    onChange={(selectedOption, input) => handleSelectChange(selectedOption, input)}
                    options={teams}
                  />
                  {errors["team"] ? <div className="form-control-feedback text-danger ml-3">{errors["team"]}</div> : ""}
                </FormGroup>
              </Col>
            </Row>
          )}

          <Row className="justify-content-center mt-3">
            {action !== "C" ? (
              <Button outline className="btn-round mr-1" color="success" type="button" onClick={handleSubmit}>
                Guardar
              </Button>
            ) : (
              ""
            )}
            <Button outline className="btn-round mr-1" color="danger" type="button" onClick={goBack}>
              {action === "C" ? "Vovler" : "Cancelar"}
            </Button>
          </Row>
        </form>
      </Container>
    </>
  );
};

export default UsersForm;
