import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { regSw, subscribe } from "registerSW.js";

const Home = ({ user }) => {
  const [subscribed, setSubscribed] = useState(false);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) {
      getPermission();
      setMounted(true);
    }
  }, []);

  const getPermission = async () => {
    const permission = await navigator.permissions.query({ name: "push", userVisibleOnly: true });
    setSubscribed(permission.state === "granted");
  };

  const revokePermission = async () => {
    navigator.serviceWorker.ready.then((reg) => {
      reg.pushManager.getSubscription().then((subscription) => {
        subscription
          .unsubscribe()
          .then((successful) => {
            setSubscribed(false);
            // You've successfully unsubscribed
          })
          .catch((e) => {
            // Unsubscribing failed
          });
      });
    });
  };

  const grantPermission = async (user) => {
    try {
      const serviceWorkerReg = await regSw();
      let subscription = await subscribe(serviceWorkerReg, user);
      if (subscription.endpoint) setSubscribed(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="wrapper " style={{ height: "100vh", backgroundImage: "url('/images/background.png')", backgroundSize: "cover", backgroundPosition: "center center" }}>
        {subscribed && (
          <Button className="btn-just-icon mt-2 mr-2  float-right" color={subscribed ? "success" : "danger"} size="lg" type="button" onClick={() => revokePermission()}>
            <i className="fa fa-bell" />
          </Button>
        )}
        {!subscribed && (
          <Button className="btn-just-icon mt-2 mr-2  float-right" color={subscribed ? "success" : "danger"} size="lg" type="button" onClick={() => grantPermission(user)}>
            <i className="fa fa-bell-o" />
          </Button>
        )}
        {/* <Row className="justify-content-center mt-5">
          <Button className="btn-magnify btn-round  mr-1" disabled={subscribed} color="danger" onClick={() => grantPermission(user)}>
            <i className="fa fa-bell mr-1" />
            Activar Notificaciones
          </Button>
          <Button className="btn-magnify btn-round  mr-1" disabled={!subscribed} color="success" onClick={() => revokePermission()}>
            <i className="fa fa-bell-o mr-1" />
            Desactivar Notificaciones
          </Button>
        </Row> */}
      </div>
    </>
  );
};

export default Home;
