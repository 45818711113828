import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button, Form, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { forgotPassword } from "../../services/userService";

const Recuperar = () => {
  const [data, setData] = useState({
    email: "",
  });
  const [sent, setSent] = useState(false);

  const doRecuperar = async (event) => {
    event.preventDefault();
    await forgotPassword(data);
    setSent(true);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      doRecuperar(event);
    }
  };

  const handleChange = ({ currentTarget: input }) => {
    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  return (
    <Row className="main-container align-items-center ">
      <Col className="card-col mx-auto" lg="4" md="6">
        {!sent && (
          <Card className="card-register">
            <h3 className="title login-title mx-auto ">
              <strong>Recuperar Contraseña</strong>
            </h3>

            <Form className="register-form" onSubmit={doRecuperar}>
              <label>e-mail</label>
              <InputGroup className="form-group-no-border">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="nc-icon nc-email-85" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input autoFocus id="email" name="email" onChange={handleChange} onKeyPress={handleKeyPress} value={data.email} placeholder="e-mail" type="email" autoComplete="email" />
              </InputGroup>
              <Button
                block
                className="btn-round"
                color="danger"
                type="button"
                onClick={(event) => {
                  doRecuperar(event);
                }}
              >
                Recuperar Contraseña
              </Button>
            </Form>
          </Card>
        )}
        {sent && (
          <div className="section text-center">
            <Row>
              <Col md="12">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-send" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Link Enviado</h4>
                    <p className="description">Enviamos un mail a tu casilla de correo, con las instrucciones para recuperar tu contraseña.</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
        {
          <Row className="mt-3 mb-1 justify-content-center">
            <Link to="/login">
              <h6 className="text-secondary">Ingreso al sistema</h6>
            </Link>
          </Row>
        }
      </Col>
    </Row>
  );
};

export default Recuperar;
