import React from "react";
import { Button, Modal, Card, CardText, CardBody, CardTitle } from "reactstrap";
import useIosInstallPrompt from "../hooks/useIosInstallPrompt";
import useWebInstallPrompt from "../hooks/useWebInstallPrompt";

export const InstallPWA = () => {
  const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
  const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] = useWebInstallPrompt();

  if (!iosInstallPrompt && !webInstallPrompt) {
    return null;
  }

  return (
    <Modal isOpen centered>
      <Card style={{ boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 15%)" }}>
        <img
          className="mx-auto"
          style={{
            borderTopRightRadius: "50%",
            borderTopLeftRadius: "50%",
            borderBottomRightRadius: "50%",
            borderBottomLeftRadius: "50%",
            backgroundColor: "#fff",
            marginTop: "-50px",
          }}
          width="100px"
          src="images/icon_512x512.png"
          alt="Icon"
        />
        <CardBody>
          <CardTitle className="text-center">
            <h3>Instalar Aplicación</h3>
          </CardTitle>
          {iosInstallPrompt && (
            <>
              <CardText className="text-center">
                Pulse el icono
                <img src="images/AppleShare.png" style={{ margin: "auto 8px 8px" }} className="" alt="Agregar a Inicio" width="20" />
                debajo, luego deslice hacia arriba el menú emergente y seleccione &quot;Agregar a Inicio&quot;
              </CardText>
              <div className="d-flex justify-content-center">
                <Button onClick={handleIOSInstallDeclined}>Cerrar</Button>
              </div>
            </>
          )}
          {webInstallPrompt && (
            <div className="d-flex justify-content-around">
              <Button color="primary" onClick={handleWebInstallAccepted}>
                Instalar
              </Button>
              <Button onClick={handleWebInstallDeclined}>Cerrar</Button>
            </div>
          )}
        </CardBody>
      </Card>
    </Modal>
  );
};
