import http from "./httpService";
import { getConfig } from "../config/config.js";

const config = getConfig();
const serviceEndpoint = config.apiEndpoint + "/teams";

export async function getTeams() {
  let items = [];
  try {
    const response = await http.get(serviceEndpoint);
    response.data.records.map((item) => {
      items.push({ value: item, label: item });
    });
    return items;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}
