import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Row, Col, Card, Button, Form, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { resetPassword } from "../../services/userService";
import { toastBigError, toastError, toastInfo } from "components/utils/toasts";

const ResetPassword = (props) => {
  const [data, setData] = useState({
    password: "",
    confirm: "",
  });
  const [sent, setSent] = useState(false);
  const params = useParams();
  const history = useHistory();

  const doReset = async (event) => {
    event.preventDefault();
    if (data.password !== data.confirm) toastBigError("las constraseñas no coinciden");
    else {
      const success = await resetPassword(params.id, params.token, data);
      if (success.data.toUpperCase().includes("ERROR")) toastError(success.data);
      else toastInfo(success.data);
      setSent({ sent: true });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      doReset(event);
    }
  };
  const handleChange = ({ currentTarget: input }) => {
    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  const goHome = () => {
    localStorage.removeItem("token");
    history.push({
      pathname: "/login",
      state: {},
    });
  };

  return (
    <Row className="main-container align-items-center ">
      <Col className="card-col mx-auto" lg="4" md="6">
        <Card className="card-register">
          <h3 className="title login-title mx-auto ">
            <strong>Cambiar Contraseña</strong>
          </h3>
          <Form className="register-form">
            {!sent && (
              <>
                <label>Nueva Contraseña</label>
                <InputGroup className="form-group-no-border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="nc-icon nc-key-25" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input autoFocus id="password" name="password" onChange={handleChange} value={data.password} placeholder="contraseña" type="password" autoComplete="off" />
                </InputGroup>
                <label>Confirmar Contraseña</label>
                <InputGroup className="form-group-no-border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="nc-icon nc-key-25" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input id="confirm" name="confirm" onChange={handleChange} onKeyPress={handleKeyPress} value={data.confirm} placeholder="confirmar contraseña" type="password" autoComplete="off" />
                </InputGroup>
                <Button
                  block
                  className="btn-round"
                  color="danger"
                  type="button"
                  onClick={(event) => {
                    doReset(event);
                  }}
                >
                  Cambiar Contraseña
                </Button>
              </>
            )}
            {sent && (
              <>
                <p>Contraseña restablecida correctamente. Ya puede ingresar al sistema.</p>
                <Button block className="btn-round" color="danger" type="button" onClick={goHome}>
                  Ingresar al sistema
                </Button>
              </>
            )}
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default ResetPassword;
