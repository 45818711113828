import React from "react";
import EventCalendar from "components/EventCalendar/eventCalendar";
import { Container, Col, Row } from "reactstrap";

const Reservar = (props) => {
  return (
    <>
      <Container className="main-container">
        <Container className="wrapper">
          <Row className="p-0">
            <Col xs="12" className="p-0">
              <h3>
                <span className="section-title note">
                  {props.view === "calendar" && <strong>Reservar Sala</strong>}
                  {props.view === "list" && <strong>Mis Eventos</strong>}
                </span>
              </h3>
            </Col>
          </Row>
          <EventCalendar user={props.user} view={props.view} />
        </Container>
      </Container>
    </>
  );
};

export default Reservar;
