import http from "./httpService";
import { getConfig } from "../config/config.js";

const config = getConfig();
const serviceEndpoint = config.apiEndpoint + "/reservas";

export async function getReserva(recordId) {
  try {
    const response = await http.get(serviceEndpoint + "/" + recordId);
    return response.data.record;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

export async function getReservas(sala, date, view) {
  try {
    const response = await http.get(serviceEndpoint, { params: { sala: sala.value, date: date, view: view } });
    let reservas = [];
    response.data.records.forEach((item) => {
      reservas.push({
        _id: item._id,
        user: item.user,
        title: item.titulo,
        titulo: item.titulo,
        sala: item.sala,
        start: new Date(item.start),
        end: new Date(item.end),
        desc: item.detalle,
        detalle: item.detalle,
        confirmada: item.confirmada,
        semanal: item.semanal,
      });
    });
    // return response.data.records;
    return reservas;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

export async function getEventos(user) {
  try {
    const response = await http.get(serviceEndpoint + "/eventos", { params: { user: user._id } });
    let reservas = [];
    response.data.records.forEach((item) => {
      reservas.push({
        _id: item._id,
        sala: item.sala.descripcion,
        user: item.user,
        title: item.titulo,
        titulo: item.titulo,
        start: new Date(item.start),
        end: new Date(item.end),
        desc: item.detalle,
        detalle: item.detalle,
        confirmada: item.confirmada,
        semanal: item.semanal,
        cancelada: item.cancelada,
      });
    });
    // return response.data.records;
    return reservas;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

export async function saveReserva(data) {
  if (data._id) {
    if (data.cancelada) {
      await http.put(serviceEndpoint + "/" + data._id, data);
      return await http.post(serviceEndpoint + "/cancel", data);
    } else {
      return await http.put(serviceEndpoint + "/" + data._id, data);
    }
  } else {
    return await http.post(serviceEndpoint, data);
  }
}

export async function confirmaReserva(data) {
  await http.put(serviceEndpoint + "/" + data._id, data);
  return await http.post(serviceEndpoint + "/confirma", data);
}

export async function notifyReserva(data) {
  return await http.post(serviceEndpoint + "/notify", data);
}

export async function deleteReserva(recordId) {
  return await http.delete(serviceEndpoint + "/" + recordId);
}
