import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Table, Button, Row, Col, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { Container } from "reactstrap";
import { toastBigError, toastConfirm } from "components/utils/toasts";
import { cleanString } from "components/utils/cleanString";
import { paginate } from "components/utils/paginate";
import { getUsers, deleteUser } from "services/userService";
import { getConfig } from "config/config";
import PaginationControls from "components/PaginationControls/paginationControls";
import { sendInvite } from "services/userService";
import { toastError } from "components/utils/toasts";
import { toastInfo } from "components/utils/toasts";

const Users = () => {
  const [allRecords, setAllRecords] = useState([]);
  const [mounted, setMounted] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState({ path: "nombre", order: "asc" });
  const [searchQuery, setSearchQuery] = useState("");

  const config = getConfig();
  const history = useHistory();
  const pageSize = config.recordsPerPage;

  const fetchData = async () => {
    try {
      const records = await getUsers();
      if (records === "NETWORK ERROR") toastBigError("Error", "Error de Conexión");
      else setAllRecords(records);
    } catch {}
  };

  if (!mounted) {
    fetchData();
    setMounted(true);
  }

  const getPagedData = () => {
    let filtered = [];
    if (searchQuery.trim().length > 0) {
      filtered = allRecords.filter((m) => cleanString(m.apellido).includes(cleanString(searchQuery)) || cleanString(m.nombre).includes(cleanString(searchQuery)));
    } else {
      filtered = allRecords;
    }
    const ordered = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const records = paginate(ordered, currentPage, pageSize);
    return { totalCount: filtered.length, records };
  };

  const { totalCount, records } = getPagedData();
  if (currentPage > Math.ceil(totalCount / pageSize) && totalCount > 0) setCurrentPage(Math.ceil(totalCount / pageSize));

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.currentTarget.value);
  };

  const handleView = (id) => {
    history.push({
      pathname: "/usuarios/" + id,
      state: { action: "C" },
    });
  };

  const handleEdit = (id) => {
    history.push({
      pathname: "/usuarios/" + id,
      state: { action: "E" },
    });
  };

  const handleInvite = async (id) => {
    const success = await sendInvite({ id: id });
    // console.log(success);
    if (success.data.toUpperCase().includes("ERROR")) toastError(success.data);
    else toastInfo(success.data);
  };

  const handleDelete = async (id) => {
    // event.preventDefault();
    toastConfirm("¿ Eliminar usuario ?", "Se eliminarán todas las reservas de este usuario", () => doDelete(id));
  };

  const doDelete = async (id) => {
    const originalRecords = allRecords;
    const records = allRecords.filter((m) => m._id !== id);
    setAllRecords(records);
    try {
      await deleteUser(id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404) toastBigError("Ese usuario ya fue eliminado");
      setAllRecords(originalRecords);
    }
  };

  const labelPages = Math.ceil(totalCount / pageSize) > 1 ? currentPage + "/" + Math.ceil(totalCount / pageSize) : "";

  return mounted ? (
    <>
      <Container className="wrapper">
        <h2 className="mt-4 mb-4">Usuarios</h2>
        <Container>
          <Row className="justify-content-between align-items-center mb-3">
            <Link to="/usuarios/nuevo">
              <Button className="btn-round mr-1 blue" type="button">
                Nuevo Usuario
              </Button>
            </Link>
            <Col xs="6">
              <InputGroup>
                <Input type="text" name="searchQuery" placeholder="Buscar..." onChange={(event) => handleSearch(event)} />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <i aria-hidden={true} className="fa fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
        </Container>
        <Table hover responsive striped className="negrita">
          <thead>
            <tr>
              <th style={{ width: "35%" }}>Nombre</th>
              <th style={{ width: "35%" }}>Apellido</th>
              <th style={{ width: "10%" }}></th>
              <th style={{ width: "10%" }}></th>
              <th style={{ width: "10%" }}></th>
            </tr>
          </thead>
          <tbody>
            {records.map((item) => (
              <tr key={Math.random()}>
                <td className="clickable" onClick={() => handleView(item._id)}>
                  <span className="black">{item.nombre}</span>
                </td>
                <td className="clickable" onClick={() => handleView(item._id)}>
                  <span className="black">{item.apellido}</span>
                </td>
                <td>
                  <span className="clickable " onClick={() => handleInvite(item._id)}>
                    <div className="icon icon-info">
                      <i className="fa fa-2x fa-envelope-o" color="info" />
                    </div>
                  </span>
                </td>
                <td>
                  <span className="clickable " onClick={() => handleEdit(item._id)}>
                    <div className="icon icon-success">
                      <i className="fa fa-2x fa-pencil-square-o" color="success" />
                    </div>
                  </span>
                </td>
                <td>
                  <span className="clickable" onClick={() => handleDelete(item._id)}>
                    <div className="icon icon-danger">
                      <i className="fa fa-2x fa-trash-o danger" />
                    </div>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Container>
          <Row className="justify-content-between align-items-center">
            <span>{labelPages}</span>
            <PaginationControls onPageChange={(page) => handlePageChange(page)} itemsCount={totalCount} pageSize={pageSize} currentPage={currentPage} />
          </Row>
        </Container>
      </Container>
    </>
  ) : (
    <></>
  );
};

export default Users;
