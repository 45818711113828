import React from "react";
import PropTypes from "prop-types";
import { Container, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import _ from "lodash";

const PaginationControls = ({ itemsCount, pageSize, currentPage, onPageChange }) => {
  const pagesCount = Math.ceil(itemsCount / pageSize);
  if (pagesCount <= 1) return null;

  let fromPage = Math.max(1, currentPage - 3);
  let toPage = Math.min(fromPage + 6, pagesCount);
  if (toPage < fromPage + 6) fromPage = Math.max(1, toPage - 6);
  const pages = _.range(fromPage, toPage + 1);

  if (pages.length === 0) return null;

  return (
    // <nav className="align-items-center" aria-label="Page navigation example">
    <Pagination style={{ marginBottom: "0px" }}>
      <PaginationItem disabled={currentPage === 1}>
        <PaginationLink href="#" aria-label="Anterior" onClick={() => onPageChange(currentPage - 1)}>
          <i aria-hidden={true} className="fa fa-angle-left" />
          <span className="sr-only">Anterior</span>
        </PaginationLink>
      </PaginationItem>

      {pages.map((page) => (
        <PaginationItem key={Math.random()}>
          <PaginationLink href="#" onClick={() => onPageChange(page)}>
            {page}
          </PaginationLink>
        </PaginationItem>
      ))}

      <PaginationItem disabled={currentPage === toPage}>
        <PaginationLink href="#" aria-label="Siguiente" id="datatable_previous" onClick={() => onPageChange(currentPage + 1)}>
          <i aria-hidden={true} className="fa fa-angle-right" />
          <span className="sr-only">Siguiente</span>
        </PaginationLink>
      </PaginationItem>
    </Pagination>
    // </nav>
  );
};

PaginationControls.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default PaginationControls;
