import axios from "axios";
import { getConfig } from "../src/config/config.js";

const config = getConfig();
const serviceEndpoint = config.apiEndpoint + "/subscribe";
const testEndpoint = config.apiEndpoint + "/testsubscription";
const deleteSubscriptionEndpoint = config.apiEndpoint + "/deletesubscription";

async function regSw() {
  if ("serviceWorker" in navigator) {
    let url = process.env.PUBLIC_URL + "/sw.js";
    const reg = await navigator.serviceWorker.register(url, { scope: "/" });
    reg.update();
    // console.log("service config is", { reg });
    return reg;
  }
  throw Error("serviceworker not supported");
}

const requestNotificationPermission = async () => {
  Notification.requestPermission().then(function (getperm) {
    // console.log("permission granted");
  });
  // const permission = await navigator.permissions.query({ name: "push", userVisibleOnly: true });
  // const permission = await window.Notification.requestPermission();
  // value of permission can be 'granted', 'default', 'denied'
  // granted: user has accepted the request
  // default: user has dismissed the notification permission popup by clicking on x
  // denied: user has denied the request.
  // const permission = navigator.permissions.query({ name: "push", userVisibleOnly: true });
  // if (permission.status !== "granted") {
  //   console.log("Permission not granted for Notification");
  //   // throw new Error("Permission not granted for Notification");
  // }
  // return permission;
};

async function subscribe(serviceWorkerReg, user) {
  let subscription = await serviceWorkerReg.pushManager.getSubscription();
  if (subscription === null) {
    subscription = await serviceWorkerReg.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: config.PUBLIC_KEY,
    });
    const data = { subscription: subscription, user: user };
    axios.post(serviceEndpoint, data);
  } else {
    // console.log("no es null");
    try {
      const response = await axios.post(testEndpoint, { user: user._id });
      if (response.data.status === 410 || response.data.status === 400) {
        subscription.unsubscribe().then(async () => {
          await axios.post(deleteSubscriptionEndpoint, { user: user._id });
          subscription = await serviceWorkerReg.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: config.PUBLIC_KEY,
          });
          const data = { subscription: subscription, user: user };
          axios.post(serviceEndpoint, data);
        });
      }
    } catch (error) {}
  }
  return subscription;
}

async function unsubscribe(serviceWorkerReg) {
  let subscription = await serviceWorkerReg.pushManager.getSubscription();
  if (subscription !== null) {
    subscription.unsubscribe();
  }
}

export { regSw, subscribe, unsubscribe, requestNotificationPermission };
